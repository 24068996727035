import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import emitter from "tiny-emitter/instance";
import * as moment from "moment";
import { connect } from 'react-redux';
import { getUserSandbox } from "./redux/slices/sandbox";

import "./index.css";

import Layout from "./Layout";
import apiData from "./services/api_data";
import AuthService from "./services/auth.service";
import { Toaster } from "react-hot-toast"

const Login = React.lazy(() => import("./screens/Login"));
const Dashboard = React.lazy(() => import("./screens/Dashboard"));
const Volume = React.lazy(() => import("./screens/Volume"));
const MediaType = React.lazy(() => import("./screens/MediaType"));
const Source = React.lazy(() => import("./screens/Source"));
const Category = React.lazy(() => import("./screens/Category"));
const Language = React.lazy(() => import("./screens/Language"));
const LSMSEM = React.lazy(() => import("./screens/LSMSEM"));
const Value = React.lazy(() => import("./screens/Value"));
const Brand = React.lazy(() => import("./screens/Brand"));
const AdvertType = React.lazy(() => import("./screens/AdvertType"));
const TopAuthors = React.lazy(() => import("./screens/TopAuthors"));
const AlertHistory = React.lazy(() => import("./screens/AlertHistory"));
const FAQ = React.lazy(() => import("./screens/FAQ"));
const Sentiment = React.lazy(() => import("./screens/Sentiment"));
const Data = React.lazy(() => import("./screens/Data"));
const Sandbox = React.lazy(() => import("./screens/Sandbox"));
const Region = React.lazy(() => import("./screens/Region"));
const WordCloud = React.lazy(() => import("./screens/WordCloud"));
const BookBuilder = React.lazy(() => import("./screens/BookBuilder"));
const Books = React.lazy(() => import("./screens/Books"));
const UserManagement = React.lazy(() => import("./screens/UserManagement"));
const ClientManagement = React.lazy(() => import("./screens/ClientManagement"));
const SocialMediaEngagement = React.lazy(() =>
	import("./screens/SocialMediaEngagement")
);
// const Alerts = React.lazy(() => import("./screens/Alert"));

class AppRouter extends React.Component {
	constructor(props) {
		super();
		this.state = {
			auth: null,
			data: null,
		};
		this.selectedClient = null;
		this.initialData = null;
	}

	componentDidMount() {
		this.initDate();
		this.initFilters();
		this.initBooks();
		this.initPressedMediaTypes();

		emitter.on("login", () => {
			const user = AuthService.getCurrentUser();
			if (user.logged)
			{
				this.setState({ auth: user }, () => this.props.getUserSandbox());
				this.getData();
			}
		});

		emitter.on("logout", (user) => {
			AuthService.logout();
		});

		emitter.on("dateRange", (range) => {
			let newRange = range;
			let startDate = moment(newRange[0].startDate).format("YYYY-MM-DD");
			let endDate = moment(newRange[0].endDate).format("YYYY-MM-DD");

			this.setState(
				{
					data: {
						...this.state.data,
						dateRange: {
							...this.state.dateRange,
							currentDataDateFrom: startDate,
							currentDataDateTo: endDate,
						},
					},
				},
				() => {
					sessionStorage.setItem(
						"dateRange",
						JSON.stringify({
							currentDataDateFrom: startDate,
							currentDataDateTo: endDate,
						})
					);
					this.getData(1);
				}
			);
		});

		emitter.on("getData", () => {
			this.getData();
		});

		emitter.on("filterChange", () => {
			// console.log('DATA INAINTE: ', data);
			this.setState(
				{
					data: {
						...this.state.data,
					},
				},
				() => { }
			);
		});

		emitter.on("restoreAnalytics", () => {
			const currentFilters = JSON.parse(
				sessionStorage.getItem("filters")
			);
			currentFilters.exclude = [];
			sessionStorage.setItem("filters", JSON.stringify(currentFilters));

			this.getData();
		});

		emitter.on("filter", (filterArray) => {
			// console.log('VALUES: ', filterArray);

			const currentFilters = JSON.parse(
				sessionStorage.getItem("filters")
			);

			filterArray.forEach((filter) => {
				if (filter.values) {
					filter.values.forEach((el) => {
						if (filter.field !== "description") {
							if (filter.field === "region")
								filter.field = "location";
							currentFilters[filter.field].push(el);
						} else {
							if (el !== "") {
								currentFilters.search = el;
							}
						}
					});
				}
			});

			sessionStorage.setItem("filters", JSON.stringify(currentFilters));
			this.getData();
		});
	}

	componentWillUnmount() {
		emitter.off("login");
		emitter.off("logout");
		emitter.off("dateRange");
		emitter.off("getData");
		emitter.off("filterChange");
		emitter.off("restoreAnalytics");
		emitter.off("filter");
	}

	initDate() {
		let dateRange = {
			currentDataDateFrom: moment().subtract(7, "d").format("YYYY-MM-DD"),
			currentDataDateTo: moment().format("YYYY-MM-DD"),
		};
		sessionStorage.setItem("dateRange", JSON.stringify(dateRange));

		this.setState({
			data: {
				...this.state.data,
				...{ dateRange },
			},
		});
	}

	initFilters() {
		const filters = {
			mediaType: [],
			brand: [],
			mediaSource: [],
			category: [],
			subCategory: [],
			type: [],
			sentiment: [],
			author: [],
			topic: [],
			advertType: [],
			language: [],
			country: [],
			location: [],
			exclude: [],
			search: "",
		};

		sessionStorage.setItem("filters", JSON.stringify(filters));
		sessionStorage.setItem("editorialChecked", true);
		sessionStorage.setItem("advertisingChecked", true);
	}

	initBooks() {
		sessionStorage.setItem("books", JSON.stringify([]));
	}

	initPressedMediaTypes() {
		sessionStorage.setItem("pressedMediaTypes", JSON.stringify([]));
	}

	getClient() {
		return JSON.parse(sessionStorage.getItem("selectedClient"));
	}

	setClient(client) {
		sessionStorage.setItem("selectedClient", JSON.stringify(client));
	}

	getData() {
		emitter.emit("loading", true);
		this.selectedClient = this.getClient();
		apiData
			.getUserClients(AuthService.getCurrentUser().userId)
			.then((response) => {
				const sortedClients = response[0].usersClientsClients.sort(
					(a, b) =>
						a.client.clientName.localeCompare(b.client.clientName)
				);

				var activeClients = sortedClients.filter(
					(clients) => clients.client.active === true
				);

				this.setState({
					data: {
						...this.state.data,
						clients: activeClients,
					},
				});

				if (!this.selectedClient) {
					this.setClient(activeClients[0].client);
					this.selectedClient = this.getClient();
				}

				if (!sessionStorage.getItem("filters")) {
					this.initDate();
					this.initFilters();
					this.initBooks();
					this.initPressedMediaTypes();
				}

				apiData
					.getClient(
						this.selectedClient.id,
						AuthService.getCurrentUser().userId,
						true
					)
					.then((response) => {
						const aClientConfigs = [];

						response[0].clientConfigs.forEach((d) => {
							const aTypes = [];
							const aCountries = [];
							const aMediaTypes = [];
							const aBrands = [];
							const aCategories = [];
							const aSubCategories = [];

							d.clientConfigBrands.forEach((m) => {
								if (!aBrands.includes(m.brand.brandName)) {
									aBrands.push(m.brand.brandName);
								}
							});

							d.clientConfigCategories.forEach((m) => {
								if (
									!aCategories.includes(
										m.category.categoryName
									)
								) {
									aCategories.push(m.category.categoryName);
								}
							});

							d.clientConfigCountries.forEach((m) => {
								if (
									!aCountries.includes(m.country.countryName)
								) {
									aCountries.push(m.country.countryName);
								}
							});

							d.clientConfigMediatypes.forEach((m) => {
								if (
									!aMediaTypes.includes(
										m.mediatype.mediatypeName
									)
								) {
									aMediaTypes.push(m.mediatype.mediatypeName);
								}
							});

							d.clientConfigSubcategories.forEach((m) => {
								if (
									!aSubCategories.includes(
										m.subCategory.subcategoryName
									)
								) {
									aSubCategories.push(
										m.subCategory.subcategoryName.toUpperCase()
									);
								}
							});

							d.clientConfigTypes.forEach((m) => {
								if (!aTypes.includes(m.type.typeName)) {
									aTypes.push(m.type.typeName);
								}
							});

							aClientConfigs.push({
								brands: aBrands,
								categories: aCategories,
								countries: aCountries,
								mediatypes: aMediaTypes,
								subcategories: aSubCategories,
								types: aTypes,
							});
						});

						const daysForPreviousData = moment
							.duration(
								moment(
									this.state.data.dateRange.currentDataDateTo
								).diff(
									moment(
										this.state.data.dateRange
											.currentDataDateFrom
									)
								)
							)
							.asDays();
						let dateForPreviousData = moment(
							this.state.data.dateRange.currentDataDateFrom
						)
							.subtract(daysForPreviousData + 1, "days")
							.format("YYYY-MM-DD");
						const dateForPreviousDataEnd = moment(
							this.state.data.dateRange.currentDataDateFrom
						)
							.subtract(1, "days")
							.format("YYYY-MM-DD");

						let dataFilter = JSON.parse(
							sessionStorage.getItem("filters")
						);

						const currClient = JSON.parse(
							sessionStorage.getItem("selectedClient")
						);

						dataFilter.clientId = currClient.id;
						dataFilter.userId = AuthService.getCurrentUser().userId;

						dataFilter.dateFrom =
							this.state.data.dateRange.currentDataDateFrom;
						dataFilter.dateTo =
							this.state.data.dateRange.currentDataDateTo;

						dataFilter.config = aClientConfigs;
						sessionStorage.setItem(
							"dataFilter",
							JSON.stringify(dataFilter)
						);

						// console.log('DATA FILTER: ', dataFilter);
						apiData
							.getAnalytics(JSON.stringify(dataFilter))
							.then((response) => {

								if (response?.dashboardAnalytics?.sentimentDistribution) {
									response.dashboardAnalytics.sentimentDistribution.forEach(item => {
										item.sentiment = item.sentiment.replace("*", "");	
									});
								}													
													
								const types = [];
								const arrMediaType =
									response.graphFilters.types;
								arrMediaType.map((x) => types.push(x));

								const countries = [];
								const arrMediaCountry =
									response.graphFilters.countries;
								arrMediaCountry.map((x) => countries.push(x));

								const mediatypes = [];
								const arrMediaMediaTypes =
									response.graphFilters.mediatypes;
								arrMediaMediaTypes.map((x) =>
									mediatypes.push(x)
								);

								const brands = [];
								const arrMediaBrands =
									response.graphFilters.brands;
								arrMediaBrands.map((x) => brands.push(x));

								const categories = [];
								const arrMediaCategory =
									response.graphFilters.categories;
								arrMediaCategory.map((x) => categories.push(x));

								const subcategories = [];
								const arrMediaSubCategory =
									response.graphFilters.subcategories;
								arrMediaSubCategory.map((x) =>
									subcategories.push(x)
								);

								const topics = [];
								const arrMediaTopics =
									response.graphFilters.topics;
								arrMediaTopics.map((x) => topics.push(x));

								const lms = [];
								const arrMediaSource =
									response.graphFilters.sources;
								arrMediaSource.map((x) =>
									lms.push({ id: x, itemName: x })
								);

								const lang = [];
								const arrLanguage =
									response.graphFilters.languages;
								arrLanguage.map((x) =>
									lang.push({ id: x, itemName: x })
								);

								const adtype = [];
								const arrAdtypes =
									response.graphFilters.advertisingtypes;
								arrAdtypes.map((x) =>
									adtype.push({ id: x, itemName: x })
								);

								const loc = [];
								const arrLocation =
									response.graphFilters.regions;
								arrLocation.map((x) =>
									loc.push({ id: x, itemName: x })
								);

								const sent = [];
								const arrSentiment =
									response.graphFilters.sentiments;
								arrSentiment.map((x) =>
									sent.push({ id: x, itemName: x })
								);

								const auth = [];
								const arrAuthor = response.graphFilters.authors;
								arrAuthor.map((x) =>
									auth.push({ id: x, itemName: x })
								);

								emitter.emit("applysubscription");
								emitter.emit("applylistsubscription");

								this.setState(
									{
										data: {
											...this.state.data,
											totalRows: response.totalRows,
											sourceDistribution:
												response.dashboardAnalytics
													.sourceDistribution,
											authorDistribution:
												response.dashboardAnalytics
													.authorDistribution,
											sentimentDistribution:
												response.dashboardAnalytics
													.sentimentDistribution,
											mediatypeDistribution:
												response.dashboardAnalytics
													.mediatypeDistribution,
											languageDistribution:
												response.dashboardAnalytics
													.languageDistribution,
											regionDistribution:
												response.dashboardAnalytics
													.regionDistribution,
											countryDistribution:
												response.dashboardAnalytics
													.countryDistribution,
											growth: response.dashboardAnalytics
												.growth,
											engagement:
												response.dashboardAnalytics
													.engagement,
											topTweets:
												response.dashboardAnalytics
													.topTweets,

											zoomSourceDistribution:
												response.zoomAnalytics
													.sourceDistribution,
											zoomRegionDistribution:
												response.zoomAnalytics
													.regionDistribution,
											zoomCategoryDistribution:
												response.zoomAnalytics
													.categoryDistribution,
											zoomLanguageDistribution:
												response.zoomAnalytics
													.languageDistribution,
											zoomBrandDistribution:
												response.zoomAnalytics
													.brandDistribution,
											zoomAdvertTypeDistribution:
												response.zoomAnalytics
													.advertTypeDistribution,
											zoomLSMDistribution:
												response.zoomAnalytics
													.lsmDistribution,
											zoomAuthorDistribution:
												response.zoomAnalytics
													.authorDistribution,
											zoomRateCardDistribution:
												response.zoomAnalytics
													.rateCardDistribution,

											dynamics:
												response.dashboardAnalytics
													.dynamics,
											mediaTypeCards:
												response.dashboardAnalytics
													.mediaTypeCards,
											mediaCampaign:
												response.dashboardAnalytics
													.mediaCampaign,

											volumeData:
												response.graphAnalytics
													.volumeGraphData,
											mediaTypeData:
												response.graphAnalytics
													.mediaTypeGraphData,
											sourceData:
												response.graphAnalytics
													.sourceGraphData,
											authorData:
												response.graphAnalytics
													.authorGraphData,
											sentimentData:
												response.graphAnalytics
													.sentimentGraphData,
											languageData:
												response.graphAnalytics
													.languageGraphData,
											ratecardData:
												response.graphAnalytics
													.ratecardGraphData,
											regionData:
												response.graphAnalytics
													.regionGraphData,
											categoryData:
												response.graphAnalytics
													.categoryGraphData,
											lsmData:
												response.graphAnalytics
													.lsmGraphData,
											brandData:
												response.graphAnalytics
													.brandGraphData,
											advertTypeData:
												response.graphAnalytics
													.advertTypeGraphData,
											wordcloudData:
												response.graphAnalytics
													.wordCloudGraphData,

											types: types,
											countries: countries,
											mediaTypes: mediatypes,
											brands: brands,
											categories: categories,
											subcategories: subcategories,
											topics: topics,
											mediaSources: lms,
											languages: lang,
											advertTypes: adtype,
											locations: loc,
											sentiment: sent,
											authors: auth,

											mediaTypeSource: response.mediaTypeSource,

											dateRange: {
												...this.state.data.dateRange,
												...{
													previousDataDateFrom:
														dateForPreviousData,
													previousDataDateTo:
														dateForPreviousDataEnd,
												},
											},
										},
									},
									() => {
										emitter.emit("loading", false);
									}
								);
							});
					});
			})
			.catch((error) => {
				console.error("GET DATA ERROR", error);
			});
	}

	render() {
		return (
			<Router>
				<Switch>
					{this.state.auth !== null ? (
						this.state.auth.logged === false ? (
							<>
								<React.Suspense fallback={<Loader />}>
									<Route path="/login" component={Login} />
								</React.Suspense>
								<Route path="*" exact>
									{() => <Redirect to={"/login"} />}
								</Route>
							</>
						) : (
							<>
								<LayoutRoute path="/" exact>
									{() => <Redirect to={"/overview"} />}
								</LayoutRoute>
								<LayoutRoute
									path="/overview"
									component={Dashboard}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/volume"
									component={Volume}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/mediaType"
									component={MediaType}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/source"
									component={Source}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/category"
									component={Category}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/language"
									component={Language}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/lsmsem"
									component={LSMSEM}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/value"
									component={Value}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/brand"
									component={Brand}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/advertType"
									component={AdvertType}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/topAuthors"
									component={TopAuthors}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/alertHistory"
									component={AlertHistory}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/faq"
									component={FAQ}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/sentiment"
									component={Sentiment}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/data"
									component={Data}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/sandbox"
									component={Sandbox}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/bookBuilder"
									component={BookBuilder}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/books"
									component={Books}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/region"
									component={Region}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/wordCloud"
									component={WordCloud}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/userManagement"
									component={UserManagement}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/clientManagement"
									component={ClientManagement}
									data={this.state.data}
									user={this.state.auth}
								/>
								<LayoutRoute
									path="/socialMediaEngagement"
									component={SocialMediaEngagement}
									data={this.state.data}
									user={this.state.auth}
								/>
							</>
						)
					) : (
						<React.Suspense fallback={<Loader />}>
							<Route exact path="/*" component={Login} />
						</React.Suspense>
					)}
				</Switch>
			</Router>
		);
	}
}

const mapDispatchToProps = { getUserSandbox };
const ReduxAppRouter = connect(null, mapDispatchToProps)(AppRouter);

function LayoutRoute({ component: Component, ...props }) {
	return (
		<React.Suspense fallback={<Loader />}>
			<Route
				{...props}
				render={(props1) => (
					<Layout {...props1} data={props.data} user={props.user}>
						<Component
							{...props1}
							data={props.data}
							user={props.user}
						/>
					</Layout>
				)}
			/>
		</React.Suspense>
	);
}

function Loader() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				height: "100%",
				zIndex: 1505,
				position: "absolute",
				top: 0,
				left: 0,
				backgroundColor: "rgba(0,0,0,0.3)",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img
				className={"loader"}
				src={require("./assets/img/logo-02.png").default}
				alt="logo"
				style={{ width: 50, height: 50 }}
			/>
			<div
				style={{
					fontSize: 15,
					fontFamily: "Avenir",
					color: "#000",
					marginTop: 20,
				}}
			>
				Intelligence Loading
			</div>
		</div>
	);
}

export default function App() {
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		emitter.on("loading", (value) => {
			setLoading(value);
		});
		return () => {
			emitter.off("loading");
		};
	});

	return (
		<>
			<ReduxAppRouter />
			{loading && <Loader />}
			<Toaster />
		</>
	);
}
