import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Box, InputBase, IconButton } from '@material-ui/core';
import emitter from 'tiny-emitter/instance';

import MainButton from '../components/MainButton';
import SelectMultipleSearch from './SelectMultipleSearch';

export default function FormFilters(props) {
    const classes = useStyles();

    const [search, setSearch] = useState(JSON.parse(sessionStorage.getItem('filters'))?.search || '');
    const [mediaType, setMediaType] = useState([]);
    const [selectedMediaType, setSelectedMediaType] = useState(JSON.parse(sessionStorage.getItem('filters'))?.mediaType || []);
    const [brand, setBrand] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(JSON.parse(sessionStorage.getItem('filters'))?.brand);
    const [mediaSource, setMediaSource] = useState([]);
    const [selectedMediaSource, setSelectedMediaSource] = useState(JSON.parse(sessionStorage.getItem('filters'))?.mediaSource);
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(JSON.parse(sessionStorage.getItem('filters'))?.category);
    const [subCategory, setSubCategory] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(JSON.parse(sessionStorage.getItem('filters'))?.subCategory);
    const [type, setType] = useState([]);
    const [selectedType, setSelectedType] = useState(JSON.parse(sessionStorage.getItem('filters'))?.type);
    const [sentiment, setSentiment] = useState([]);
    const [selectedSentiment, setSelectedSentiment] = useState(JSON.parse(sessionStorage.getItem('filters'))?.sentiment);
    const [author, setAuthor] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState(JSON.parse(sessionStorage.getItem('filters'))?.author);
    const [topic, setTopic] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(JSON.parse(sessionStorage.getItem('filters'))?.topic);
    const [advertType, setAdvertType] = useState([]);
    const [selectedAdvertType, setSelectedAdvertType] = useState(JSON.parse(sessionStorage.getItem('filters'))?.advertType);
    const [language, setLanguage] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(JSON.parse(sessionStorage.getItem('filters'))?.language);
    const [country, setCountry] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(JSON.parse(sessionStorage.getItem('filters'))?.country);
    const [location, setLocation] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(JSON.parse(sessionStorage.getItem('filters'))?.location);

    useEffect(() => {
        setMediaType(props.data.mediaTypes);
        setBrand(props.data.brands);
        setMediaSource(props.data.mediaSources);
        setCategory(props.data.categories);
        setSubCategory(props.data.subcategories);
        setType(props.data.types);
        setSentiment(props.data.sentiment);
        setAuthor(props.data.authors);
        setTopic(props.data.topics);
        setAdvertType(props.data.advertTypes);
        setLanguage(props.data.languages);
        setCountry(props.data.countries);
        setLocation(props.data.locations);
    }, [props.data]);

    useEffect(() => {
        if (selectedMediaType.length) {
            const mt = selectedMediaType.map(e => props.data.mediaTypes[e]);
            const filteredSources = props?.data?.mediaTypeSource.filter(obj => mt.includes(obj.mediaType));
            setMediaSource(filteredSources.map(e => {
                return ({
                    id: e.source,
                    itemName: e.source
                })
            }));
        }
    }, [selectedMediaType]);

    function applyFilters() {
        let filters = {
            mediaType: selectedMediaType,
            brand: selectedBrand,
            mediaSource: selectedMediaSource,
            category: selectedCategory,
            subCategory: selectedSubCategory,
            type: selectedType,
            sentiment: selectedSentiment,
            author: selectedAuthor,
            topic: selectedTopic,
            advertType: selectedAdvertType,
            language: selectedLanguage,
            country: selectedCountry,
            location: selectedLocation,
            exclude: [],
            search: search,
        }
        
        sessionStorage.setItem('filters', JSON.stringify(filters));

        emitter.emit('filter', [
            { field: 'mediaType', values: selectedMediaType.map(e => props.data.mediaTypes[e]) },
            { field: 'brand', values: selectedBrand.map(e => props.data.brands[e]) },
            { field: 'mediaSource', values: selectedMediaSource.map(e => mediaSource[e]?.itemName ?? props.data.mediaSources[e].itemName) },
            { field: 'category', values: selectedCategory.map(e => props.data.categories[e]) },
            { field: 'subCategory', values: selectedSubCategory.map(e => props.data.subcategories[e]) },
            { field: 'type', values: selectedType.map(e => props.data.types[e]) },
            { field: 'sentiment', values: selectedSentiment.map(e => props.data.sentiment[e].itemName) },
            { field: 'author', values: selectedAuthor.map(e => props.data.authors[e].itemName) },
            { field: 'topic', values: selectedTopic.map(e => props.data.topics[e]) },
            { field: 'advertType', values: selectedAdvertType.map(e => props.data.advertTypes[e].itemName) },
            { field: 'language', values: selectedLanguage.map(e => props.data.languages[e].itemName) },
            { field: 'country', values: selectedCountry.map(e => props.data.countries[e]) },
            { field: 'region', values: selectedLocation.map(e => props.data.locations[e].itemName) },
            { field: 'description', values: [search] },
        ]);
    }

    function resetFilters() {
        setSelectedMediaType([]);
        setSelectedBrand([]);
        setSelectedMediaSource([]);
        setSelectedCategory([]);
        setSelectedSubCategory([]);
        setSelectedType([]);
        setSelectedSentiment([]);
        setSelectedAuthor([]);
        setSelectedTopic([]);
        setSelectedAdvertType([]);
        setSelectedLanguage([]);
        setSelectedCountry([]);
        setSelectedLocation([]);
        setSearch('');

        let filters = {
            mediaType: [],
            brand: [],
            mediaSource: [],
            category: [],
            subCategory: [],
            type: [],
            sentiment: [],
            author: [],
            topic: [],
            advertType: [],
            language: [],
            country: [],
            location: [],
            exclude: [],
            search: '',
        }

        sessionStorage.setItem('filters', JSON.stringify(filters));
        sessionStorage.setItem('editorialChecked', false);
        sessionStorage.setItem('advertisingChecked', false);

        props.onClose();
        emitter.emit('getData');
        emitter.emit('resetAll');
    }

    return (
        <Grid container display="flex" className={classes.container} spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
                <Box display="flex" flexDirection="row" alignItems="flex-end" >
                    <Box display="flex" flexDirection="row" className={classes.search}>
                        <Box style={{ marginRight: 20, marginLeft: 10 }}>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: 13 }}>
                                <path opacity="0.8" d="M8.24365 16.4517C9.95605 16.4517 11.5352 15.8979 12.8271 14.9751L17.6875 19.8354C17.9131 20.061 18.2104 20.1738 18.5283 20.1738C19.1948 20.1738 19.6665 19.6611 19.6665 19.0049C19.6665 18.6973 19.564 18.4102 19.3384 18.1846L14.5088 13.3447C15.5239 12.0117 16.1289 10.3608 16.1289 8.56641C16.1289 4.229 12.5811 0.681152 8.24365 0.681152C3.90625 0.681152 0.358398 4.229 0.358398 8.56641C0.358398 12.9038 3.90625 16.4517 8.24365 16.4517ZM8.24365 14.7495C4.85986 14.7495 2.06055 11.9502 2.06055 8.56641C2.06055 5.18262 4.85986 2.3833 8.24365 2.3833C11.6274 2.3833 14.4268 5.18262 14.4268 8.56641C14.4268 11.9502 11.6274 14.7495 8.24365 14.7495Z" fill="black" />
                            </svg>
                        </Box>
                        <InputBase
                            style={{ width: '100%', height: '100%' }}
                            defaultValue=""
                            value={search}
                            placeholder={'Search keywords'}
                            inputProps={{ 'aria-label': 'search-input' }}
                            onChange={value => setSearch(value.target.value)}
                        />
                        {search &&
                            <IconButton
                                onClick={() => setSearch('')}
                                aria-label="delete" size="small" style={{ marginRight: 10 }}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.2" d="M11.2944 21.0557C17.0161 21.0557 21.7534 16.3184 21.7534 10.5967C21.7534 4.88525 17.0059 0.137695 11.2842 0.137695C5.57275 0.137695 0.835449 4.88525 0.835449 10.5967C0.835449 16.3184 5.58301 21.0557 11.2944 21.0557ZM7.61328 15.1494C7.1416 15.1494 6.75195 14.7598 6.75195 14.2778C6.75195 14.042 6.84424 13.8369 7.0083 13.6831L10.0742 10.6069L7.0083 7.53076C6.84424 7.38721 6.75195 7.17188 6.75195 6.93604C6.75195 6.46436 7.1416 6.08496 7.61328 6.08496C7.85938 6.08496 8.0542 6.17725 8.20801 6.34131L11.2842 9.40723L14.3809 6.33105C14.5552 6.14648 14.75 6.06445 14.9756 6.06445C15.4575 6.06445 15.8369 6.4541 15.8369 6.92578C15.8369 7.16162 15.7651 7.35645 15.5806 7.52051L12.5044 10.6069L15.5703 13.6626C15.7344 13.8267 15.8267 14.0317 15.8267 14.2778C15.8267 14.7598 15.4473 15.1494 14.9653 15.1494C14.7192 15.1494 14.5039 15.0469 14.3501 14.8931L11.2842 11.8169L8.22852 14.8931C8.07471 15.0571 7.85938 15.1494 7.61328 15.1494Z" fill="black" />
                                </svg>
                            </IconButton>
                        }
                    </Box>
                </Box>
            </Grid>

            <Grid item lg={12} md={12} xs={12}>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={mediaType}
                            onValuesChange={(values) => setSelectedMediaType(values)}
                            placeholder='Media Type'
                            userSelectedValues={selectedMediaType?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={brand}
                            onValuesChange={(values) => setSelectedBrand(values)}
                            placeholder='Brand'
                            userSelectedValues={selectedBrand?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={mediaSource}
                            onValuesChange={(values) => setSelectedMediaSource(values)}
                            placeholder='Media Source'
                            userSelectedValues={selectedMediaSource?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={category}
                            onValuesChange={(values) => setSelectedCategory(values)}
                            placeholder='Category'
                            userSelectedValues={selectedCategory?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={subCategory}
                            onValuesChange={(values) => setSelectedSubCategory(values)}
                            placeholder='Sub Category'
                            field="subcategory"
                            userSelectedValues={selectedSubCategory?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={type}
                            onValuesChange={(values) => setSelectedType(values)}
                            placeholder='Type'
                            userSelectedValues={selectedType?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={sentiment}
                            onValuesChange={(values) => setSelectedSentiment(values)}
                            placeholder='Sentiment'
                            userSelectedValues={selectedSentiment?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={author}
                            onValuesChange={(values) => setSelectedAuthor(values)}
                            placeholder='Author'
                            userSelectedValues={selectedAuthor?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={topic}
                            onValuesChange={(values) => setSelectedTopic(values)}
                            placeholder='Topic'
                            userSelectedValues={selectedTopic?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={advertType}
                            onValuesChange={(values) => setSelectedAdvertType(values)}
                            placeholder='Advert Type'
                            userSelectedValues={selectedAdvertType?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={language}
                            onValuesChange={(values) => setSelectedLanguage(values)}
                            placeholder='Language'
                            userSelectedValues={selectedLanguage?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={country}
                            onValuesChange={(values) => setSelectedCountry(values)}
                            placeholder='Country'
                            userSelectedValues={selectedCountry?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={3} xs={3}>
                        <SelectMultipleSearch
                            values={location}
                            onValuesChange={(values) => setSelectedLocation(values)}
                            placeholder='Location'
                            userSelectedValues={selectedLocation?.filter(item => typeof item === 'string').map(item => ({ value: item, label: item }))}
                        />
                    </Grid>
                </Grid>
            </Grid >

            <Grid item lg={12} md={12} xs={12}>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={3} xs={3}>
                        <MainButton
                            style={{ width: '100%' }}
                            text="Apply Filters"
                            onClick={() => { applyFilters(); props.onClose(); }}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <MainButton
                            style={{ width: '100%', backgroundColor: '#F1F1F1', color: '#000' }}
                            text="Reset filters"
                            onClick={() => { resetFilters(); }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        backgroundColor: '#fff',
        padding: 30,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    search: {
        width: '50%',
        height: 48,
        borderRadius: 5,
        border: '1px solid #E8E8E8',
    },
    formControl: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        borderRadius: 5,
        border: '1px solid #E8E8E8',
    },
    select: {
        display: 'flex',
        flex: 1,
    },
    chip: {
        backgroundColor: '#3966D0',
        padding: '3, 3, 3, 3',
        color: '#fff',
        margin: 0,
        marginRight: 5,
        paddingLeft: 10,
        fontSize: 15,
        fontFamily: 'Poppins',
        fontWeight: 400,
        borderRadius: 2,
        marginLeft: 5
    },
    border: {
        width: '100%',
        height: 48,
        borderRadius: 5,
        border: '1px solid #E8E8E8',
    },
    menuPaper: {
        maxHeight: 300
    },
    chipPlus: {
        height: 35, backgroundColor: '#3966D0', textAlign: 'center', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 5, marginRight: 5, paddingLeft: 10, paddingRight: 10
    }
}));